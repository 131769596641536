<template>
  <div class="mainPage">
    <Header />
    <div class="mainContainer">
      <div class="controlPanelContainer">
        <div class="sideBar">
          <div class="hamburgerMenu" @click="onHamburgerMenuClick()">
            <div class="menuIcon">
              <IconsWorker icon="hamburgerMenuF" width="17" color="#fff"/>
            </div>
            <span>القائمة</span>
          </div>
          <RouterLink class="cell" :to="link.link" v-for="link in getPages()" :key="link.link" v-if="state.isHamburgerMenuShowed">
            <IconsWorker :icon="link.icon" width="17" color="#666"/>
            <span>{{ link.text }}</span>
          </RouterLink>
        </div>
        <div class="contentSide">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, reactive} from "vue";
import myAccountAsyncStore from "../store/myAccountAsyncStore";
import router from "../router";
import IconsWorker from "../components/IconsWorker";
import Header from "../components/Header";
import BrowserHelper from "../helpers/BrowserHelper";

export default {
  name: "ControlPanel",
  components: {Header, IconsWorker},
  setup() {
    const state = reactive({
      navItems: [
        { text: 'المستخدمين', icon: 'userF', link: '/controlPanel/users' },
        { text: 'الأقسام', icon: 'tagF', link: '/controlPanel/classefications/cat' },
        { text: 'المناطق', icon: 'locationMarkerF', link: '/controlPanel/classefications/area' },
        { text: 'الأماكن', icon: 'imagesF', link: '/controlPanel/applys' },
        { text: 'التعليقات', icon: 'commentF', link: '/controlPanel/comments' },
        { text: 'معلومات الإتصال', icon: 'callF', link: '/controlPanel/contactInfoSettings' },
        { text: 'البريد الوارد', icon: 'telegramF', link: '/controlPanel/contactUsMessages' },
        { text: 'الصفحات الثابتة', icon: 'webSiteLayoutsF', link: '/controlPanel/fixedPages' },
      ],
      isHamburgerMenuShowed: false,
    });
    onMounted(() => {
      if(window.innerWidth > 600) {
        state.isHamburgerMenuShowed = true;
      }
      BrowserHelper.changeTitle('لوحة التحكم');
    });
    const getPages = () => {
      return state.navItems;
    }
    const getIsLogin = () => {
      return myAccountAsyncStore.getters.isLogin;
    };
    if(!getIsLogin()) {
      router.replace('/Login');
    }
    const onHamburgerMenuClick = () => {
      state.isHamburgerMenuShowed = !state.isHamburgerMenuShowed;
    }
    return {
      getIsLogin,
      state,
      getPages,
      onHamburgerMenuClick,
    };
  }
}
</script>

<style scoped>
.mainPage{
  background: #eee;
  min-height: 750px;
}
.controlPanelContainer{
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
}
.sideBar{
  width: 26%;
  padding: 20px 2%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px #ccc;
}

.sideBar .cell{
  width: 100%;
  padding: 15px 0px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 17px;
  align-items: start;
  text-decoration: none;
  color: #000;
}
.contentSide{
  flex: 1;
  padding: 20px 1%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px #ccc;
}
.selected{
  font-weight: bold;
}

.hamburgerMenu{
  width: 100%;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  display: none;
}
.hamburgerMenu .menuIcon{
  padding: 7px;
  background: #000;
  border-radius: 100px;
}

.hamburgerMenu:hover{ opacity: 0.8; }
.hamburgerMenu:active{ opacity: 0.5; }

@media only screen and (max-width: 600px) {
  .controlPanelContainer{
    flex-direction: column;
  }
  .sideBar{
    width: 96%;
  }
  .hamburgerMenu{
    display: flex;
  }
}
</style>